import { toCanvas } from 'html-to-image';
import { format } from 'date-fns';

import { STATES } from '../shared';
import { isFunction } from 'lodash-es';

interface Params {
  chart: {
    transform: string;
    transition: string;
  };
  canvas: {
    width: number;
    height: number;
    fitsOnOnePage: boolean;
    sourceWidth: number;
    sourceHeight: number;
  };
  pdfOptions: {
    orientation?: any;
    unit?: string;
    format?: string;
    compressPdf?: number;
  };
}

interface PDFParams {
  widthEdge: number;
  heightEdge: number;
  commonWidth: number;
  commonHeight: number;
  sourceX: number;
  sourceY: number;
  sourceWidth: number;
  sourceHeight: number;
  destX: number;
  destY: number;
  destWidth: number;
  destHeight: number;
  aspectRatio: number;
}

interface CropSizes {
  [key: string]: {
    width: number;
    height: number;
  };
}

type ChartPageImages = [string, PDFParams][];

interface ExportOrgChartParams {
  chart: HTMLElement;
  generatePdf?: boolean;
  createImageTiles?: boolean;
  labName?: string;
  callback?: Function;
}

/**
 * A module for generating a PDF version of a lab's organization chart.
 *
 * @param chart The chart as an Element.
 * @param labName The name of the current lab.
 * @param generatePdf
 * @param callback A function to execute once the job is done.
 *
 * @export
 */
export function exportOrgChart({
  chart,
  labName = 'Lab',
  generatePdf = false,
  callback
}: ExportOrgChartParams) {
  /**
   * The base name of the exported file.
   */
  const FILE_BASE_NAME = I18n.t('manage_lab.sections.org_chart.title');
  /**
   * Whether or not the final document will be landscape.
   */
  const IS_LANDSCAPE = chart.clientWidth > chart.clientHeight;
  /**
   * A short orientation string for configuring jsPDF.
   */
  const ORIENTATION_KEY = IS_LANDSCAPE ? 'l' : 'p';
  /**
   * The active page scaling factor, used for sizing computations.
   */
  const SCALING_FACTOR = 2;
  /**
   * The aspect ratio of a letter-size sheet of paper, expressed
   * as a decimal.
   */
  const PAGE_ASPECT_RATIO_FACTOR = 0.772727273;
  /**
   * A base direction for size lookups.
   */
  const BASE_DIRECTION = IS_LANDSCAPE ? 'width' : 'height';
  /**
   * The opposite direction of the base direction.
   */
  const OPPOSING_DIRECTION = IS_LANDSCAPE ? 'height' : 'width';

  // the following dimensions are all in points

  /**
   * The longer edge of a letter-size sheet.
   */
  const PAGE_LONG_EDGE = 792;
  /**
   * The shorter edge of a letter-size sheet.
   */
  const PAGE_SHORT_EDGE = 612;
  /**
   * A margin for all sides of the document.
   */
  const BASE_MARGIN = 18;
  /**
   * Additional margin for the top to allow room for a title.
   */
  const TOP_MARGIN = BASE_MARGIN + 40;
  /**
   * Baseline values used for computing crop points within large charts.
   */
  const SOURCE_CROP_SIZES: CropSizes = {
    l: {
      width: 1980,
      height: 1530
    },
    p: {
      width: 1352,
      height: 1750
    }
  };

  const params = getParams();

  setChartProcessingState(true);

  return generateCanvasFromChart().then(async (canvas: HTMLCanvasElement) => {
    setChartProcessingState(false);
    const chartPageImages = await createChartPageImages(canvas);

    // at this point the image from lab 195 is one very wide image

    if (!generatePdf) return chartPageImages;
    generatePdfFromImages(chartPageImages);
  });

  /**
   * Generates a PDF from provided page images and saves it.
   *
   * @param chartPageImages The chart as a canvas.
   */
  function generatePdfFromImages(chartPageImages: ChartPageImages) {
    const { pdfOptions, canvas } = params;

    const pdf = new jsPDF(pdfOptions);

    pdf.addFont('Rubik-Medium.ttf', 'Rubik', 'normal');
    pdf.addFont('Rubik 300.ttf', 'Rubik', 'light');

    pdf.setFont('Rubik');

    pdf.setFontStyle('normal');
    pdf.setFontSize(20);
    pdf.setTextColor(51, 51, 51);
    pdf.text(labName, 18, 24);

    pdf.setFontStyle('light');
    pdf.setFontSize(16);
    pdf.setTextColor(161, 161, 161);
    pdf.text(
      `${FILE_BASE_NAME} — Generated ${format(new Date(), 'MM/DD/YYYY')}`,
      18,
      42
    );

    chartPageImages.forEach(
      (
        [
          image,
          { widthEdge, heightEdge, aspectRatio, commonHeight, commonWidth }
        ],
        index,
        allImages
      ) => {
        const pdfYPos = (() => {
          if (!IS_LANDSCAPE || canvas.fitsOnOnePage) return TOP_MARGIN;

          if (aspectRatio !== PAGE_ASPECT_RATIO_FACTOR) {
            if (aspectRatio > 0.5) {
              return commonHeight * (1 - aspectRatio) + BASE_MARGIN;
            }

            return commonHeight * aspectRatio + BASE_MARGIN;
          }
        })();

        if (index > 0) {
          pdf.addPage([widthEdge, heightEdge]);
          pdf.setPage(index + 1);
        }

        if (allImages.length > 1) {
          pdf.addImage(
            image,
            'PNG',
            BASE_MARGIN,
            pdfYPos,
            commonWidth,
            commonHeight,
            undefined,
            'MEDIUM'
          );
        } else {
          const imageProps = pdf.getImageProperties(image);
          const pdfWidth = pdf.internal.pageSize.getWidth() - BASE_MARGIN * 2;
          const pdfHeight = (imageProps.height * pdfWidth) / imageProps.width;

          pdf.addImage(
            image,
            'PNG',
            BASE_MARGIN,
            pdfYPos,
            pdfWidth,
            pdfHeight,
            undefined,
            'MEDIUM'
          );
        }
      }
    );

    pdf.save(
      `${labName}_${FILE_BASE_NAME}_${format(new Date(), 'MM-DD-YYYY')}.pdf`
    );

    if (isFunction(callback)) callback();
  }

  /**
   * Creates a single chart page image.
   *
   * @param index The index of the current page image.
   * @param img The source image file.
   * @param enableTiling If true, draw a section of the chart based on the provided index.
   */
  function createChartPageImage(
    index: number,
    img: HTMLImageElement,
    enableTiling = false
  ) {
    const preparedPDFParams = preparePDFParams(index);
    const {
      commonWidth,
      commonHeight,
      sourceX,
      sourceY,
      sourceWidth,
      sourceHeight,
      destX,
      destY,
      destWidth,
      destHeight
    } = preparedPDFParams;

    const c = document.createElement('canvas');

    const ctx = c.getContext('2d');
    const ctxScalingFactor = window.devicePixelRatio === 2 ? 1 : 2;

    c.width = commonWidth * 2;
    c.height = commonHeight * 2;
    c.style.width = `${commonWidth}px`;
    c.style.height = `${commonHeight}px`;
    ctx.scale(
      SCALING_FACTOR * ctxScalingFactor,
      SCALING_FACTOR * ctxScalingFactor
    );

    ctx.fillStyle = '#FFFFFF';
    ctx.fillRect(0, 0, c.width, c.height);

    if (enableTiling) {
      ctx.drawImage(
        img,
        sourceX,
        sourceY,
        sourceWidth,
        sourceHeight,
        destX,
        destY,
        destWidth,
        destHeight
      );
    } else {
      c.width = img.naturalWidth;
      c.height = img.naturalHeight;
      ctx.drawImage(img, 0, 0);
    }

    return { preparedPDFParams, c };
  }

  /**
   * Tiles a provided `canvas` into several images which can be seamed
   * back together. Optionally can just return the canvas as a single image.
   *
   * @param chartCanvas A canvas containing the org chart, rendered from HTML.
   * @param createImageTiles If false, return a single image.
   */
  function createChartPageImages(chartCanvas: HTMLCanvasElement) {
    const { canvas } = params;
    const baselineSize = SOURCE_CROP_SIZES[ORIENTATION_KEY][BASE_DIRECTION];
    const baselineLength = canvas[BASE_DIRECTION];
    const pageCount =
      baselineLength > baselineSize
        ? Math.ceil(baselineLength / baselineSize)
        : 1;
    const img = new Image(chartCanvas.width, chartCanvas.height);
    const createImageTiles = IS_LANDSCAPE
      ? img.width > SOURCE_CROP_SIZES.l.width * 1.5
      : img.height > SOURCE_CROP_SIZES.p.height * 1.5;

    return new Promise<ChartPageImages>(resolve => {
      img.onload = () => {
        let chartPageImages: ChartPageImages = [];

        if (createImageTiles) {
          for (let i = 0; i <= pageCount; i++) {
            const { preparedPDFParams, c } = createChartPageImage(i, img, true);

            chartPageImages.push([
              c.toDataURL(c.toDataURL('image/png', 1.0)),
              preparedPDFParams
            ]);
          }
        } else {
          const { preparedPDFParams, c } = createChartPageImage(0, img);
          chartPageImages.push([
            c.toDataURL(c.toDataURL('image/png', 1.0)),
            preparedPDFParams
          ]);
        }

        resolve(chartPageImages);
      };

      img.src = chartCanvas.toDataURL('image/png');
    });
  }

  /**
   * Generate the canvas-version of the chart.
   */
  async function generateCanvasFromChart() {
    return toCanvas(chart, {
      width: params.canvas.width,
      height: params.canvas.height
    });
  }

  /**
   * Set a visual state on the chart while processing the PDF.
   *
   * @param isProcessing Is the export processing?
   */
  function setChartProcessingState(isProcessing: boolean) {
    if (isProcessing) {
      params.chart.transform = chart.style.transform;
    }

    setTimeout(
      () => {
        chart.classList.toggle('can-transition-opacity-slow', isProcessing);
      },
      isProcessing ? 0 : 750
    );

    chart.style.transition = isProcessing ? 'none' : params.chart.transition;
    chart.style.transform = isProcessing ? '' : params.chart.transform;

    chart.parentElement.classList.toggle(STATES.isWorking, isProcessing);
  }

  /**
   * Prepare all params required to draw the current PDF page.
   *
   * @param index The current page index.
   */
  function preparePDFParams(index: number): PDFParams {
    const {
      canvas: { sourceWidth, sourceHeight, fitsOnOnePage }
    } = params;

    const widthEdge = IS_LANDSCAPE ? PAGE_LONG_EDGE : PAGE_SHORT_EDGE;
    const heightEdge = IS_LANDSCAPE ? PAGE_SHORT_EDGE : PAGE_LONG_EDGE;
    const commonWidth = widthEdge - BASE_MARGIN * 2;
    const commonHeight = heightEdge - BASE_MARGIN * 2;

    const computedSourceWidth = (() => {
      if (!IS_LANDSCAPE && fitsOnOnePage) {
        return sourceHeight * PAGE_ASPECT_RATIO_FACTOR;
      }

      return sourceWidth;
    })();

    const computedSourceHeight = (() => {
      if (IS_LANDSCAPE) {
        return sourceWidth * PAGE_ASPECT_RATIO_FACTOR;
      }

      return sourceHeight;
    })();

    const sourceX = (() => {
      const x = IS_LANDSCAPE
        ? computedSourceWidth * window.devicePixelRatio * index -
          BASE_MARGIN * 2
        : 0;
      return x >= 0 ? x : 0;
    })();

    const sourceY = (() => {
      const y = IS_LANDSCAPE
        ? 0
        : computedSourceHeight * window.devicePixelRatio * index -
          BASE_MARGIN * 2;
      return y >= 0 ? y : 0;
    })();

    const aspectRatio =
      params.canvas[OPPOSING_DIRECTION] / params.canvas[BASE_DIRECTION];

    return {
      widthEdge,
      heightEdge,
      commonWidth,
      commonHeight,
      sourceX,
      sourceY,
      sourceWidth: computedSourceWidth * SCALING_FACTOR,
      sourceHeight: computedSourceHeight * SCALING_FACTOR,
      destX: 0,
      destY: 0,
      destWidth: commonWidth,
      destHeight: commonHeight,
      aspectRatio
    };
  }

  // function fitImageToCanvas(img: HTMLImageElement, ctx: CanvasRenderingContext2D) {
  //   // const canvas = ctx.canvas;
  //   const ratio = IS_LANDSCAPE ? img.height / img.width : img.width / img.height;
  //
  //   ctx.drawImage(
  //     img, 0, 0, img.width, img.height, 0, 0, de, PAGE_SHORT_EDGE
  //   );
  //
  //   // let newWidth = canvas.width;
  //   // let newHeight = newWidth / wrh;
  //   //
  //   // if (newHeight > canvas.height) {
  //   //   newHeight = canvas.height;
  //   //   newWidth = newHeight * wrh;
  //   // }
  //   //
  //   // ctx.drawImage(img, 0, 0, newWidth, newHeight);
  // }

  /**
   * Generate a set of dynamic properties to use within other methods.
   */
  function getParams(): Params {
    const baseWidth = SOURCE_CROP_SIZES[ORIENTATION_KEY].width;
    const baseHeight = SOURCE_CROP_SIZES[ORIENTATION_KEY].height;

    const sourceWidth = ((): number => {
      if (chart.clientWidth < baseWidth) {
        return chart.clientWidth;
      }

      return baseWidth;
    })();

    const sourceHeight = ((): number => {
      if (chart.clientHeight < baseHeight) {
        return chart.clientHeight;
      }

      return baseHeight;
    })();

    const fitsOnOnePage = ((): boolean => {
      switch (IS_LANDSCAPE) {
        case true:
          return sourceWidth < baseWidth;
        case false:
          return sourceHeight < baseHeight;
      }
    })();

    return {
      chart: {
        transform: chart.style.transform,
        transition: window.getComputedStyle(chart).transition
      },
      canvas: {
        width: chart.clientWidth,
        height: chart.clientHeight,
        fitsOnOnePage,
        sourceWidth,
        sourceHeight
      },
      pdfOptions: {
        orientation: ORIENTATION_KEY,
        unit: 'pt',
        format: 'letter'
      }
    };
  }
}
